exports.components = {
  "component---src-pages-404-en-tsx": () => import("./../../../src/pages/404/en.tsx" /* webpackChunkName: "component---src-pages-404-en-tsx" */),
  "component---src-pages-about-en-tsx": () => import("./../../../src/pages/about/en.tsx" /* webpackChunkName: "component---src-pages-about-en-tsx" */),
  "component---src-pages-about-id-tsx": () => import("./../../../src/pages/about/id.tsx" /* webpackChunkName: "component---src-pages-about-id-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index/en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-works-a-7-pro-tsx": () => import("./../../../src/pages/our-works/a7pro.tsx" /* webpackChunkName: "component---src-pages-our-works-a-7-pro-tsx" */)
}

